import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93')
];

export const server_loads = [0,7,3];

export const dictionary = {
		"/(customer)": [~61,[6]],
		"/(admin)/accounts/po-import": [~11,[2]],
		"/(admin)/accounts/purchase-orders": [~12,[2]],
		"/(customer)/account": [~62,[6,7]],
		"/(customer)/account/orders": [~63,[6,7]],
		"/(customer)/account/orders/[id]": [~64,[6,7]],
		"/(customer)/account/password-change": [~65,[6,7]],
		"/(admin)/admin": [13,[2]],
		"/(admin)/admin/bagel-orders/[locationCid=locationCid]": [~14,[2]],
		"/(admin)/admin/bagel-orders/[locationCid=locationCid]/[year]-[month]-[day]": [~15,[2]],
		"/(admin)/admin/beans": [~16,[2]],
		"/(admin)/admin/beans/country-masl-list": [~17,[2]],
		"/(admin)/admin/beans/green-stock-list": [~18,[2]],
		"/(admin)/admin/beans/grind-record": [~19,[2]],
		"/(admin)/admin/beans/planning": [~20,[2]],
		"/(admin)/admin/daily-checklist/[location]": [~21,[2]],
		"/(admin)/admin/daily-checklist/[location]/[year]-[month]-[day]": [~22,[2]],
		"/(admin)/admin/events": [~23,[2]],
		"/(admin)/admin/food/shelf-life-sheet/[location]": [~24,[2]],
		"/(admin)/admin/hiring": [~25,[2]],
		"/(admin)/admin/hiring/[id]/availability": [26,[2,3]],
		"/(admin)/admin/hiring/[id]/info": [~27,[2,3]],
		"/(admin)/admin/hiring/[id]/questions": [28,[2,3]],
		"/(admin)/admin/jobs": [~29,[2]],
		"/(admin)/admin/label-printing/cups": [~30,[2]],
		"/(admin)/admin/lightspeed/query": [~31,[2]],
		"/(admin)/admin/lightspeed/webhooks": [~32,[2]],
		"/(admin)/admin/media-asset-list": [~33,[2]],
		"/(admin)/admin/online-ordering": [~34,[2,4]],
		"/(admin)/admin/online-ordering/test-console": [35,[2,4]],
		"/(admin)/admin/ordering-sheet/[location]": [~36,[2]],
		"/(admin)/admin/roasting": [37,[2]],
		"/(admin)/admin/roasting/accounts": [~38,[2]],
		"/(admin)/admin/roasting/order/[location=locationCid]": [~39,[2]],
		"/(admin)/admin/roasting/schedule": [~40,[2]],
		"/(admin)/admin/roasting/schedule/print": [~41,[2]],
		"/(admin)/admin/shopping-list/[location]": [~42,[2]],
		"/(admin)/admin/shortlinks": [~43,[2]],
		"/(admin)/admin/staff-hours-list/[location_cid]": [~44,[2]],
		"/(admin)/admin/subscriptions": [~45,[2]],
		"/(admin)/admin/table-numbers/[location_cid=locationCid]": [~46,[2]],
		"/(admin)/admin/users": [~47,[2]],
		"/(admin)/admin/users/access-levels": [48,[2]],
		"/(admin)/admin/users/resource-access": [49,[2]],
		"/(admin)/blend-guide": [~50,[2]],
		"/(customer)/b/v2/[qrcode]": [~66,[6]],
		"/(customer)/checkout": [~67,[6]],
		"/(customer)/checkout/pay": [68,[6]],
		"/(customer)/checkout/success/[id]": [~69,[6]],
		"/(customer)/events/august-24-bicton-cupping": [~70,[6]],
		"/(customer)/events/august-24-bicton-cupping/thank-you": [71,[6]],
		"/(customer)/faq": [72,[6]],
		"/(customer)/join": [~73,[6]],
		"/(customer)/join/thankyou": [74,[6]],
		"/(admin)/links": [~51,[2]],
		"/(customer)/locations": [75,[6]],
		"/(customer)/login": [~76,[8]],
		"/(customer)/login/reset": [~77,[8]],
		"/(customer)/login/reset/ok": [78,[8]],
		"/(customer)/order": [79,[6,9]],
		"/(customer)/order/(products)/[category=category]": [80,[6,9]],
		"/(customer)/order/(products)/[category=category]/[product]": [81,[6,9]],
		"/(customer)/password-reset": [~82,[6]],
		"/(customer)/privacy-policy": [83,[6]],
		"/(admin)/site-admin/[location]/beans": [~52,[2]],
		"/(admin)/site-admin/[location]/food-guide": [~53,[2]],
		"/(admin)/site-admin/[location]/online-products": [~54,[2]],
		"/(admin)/site-admin/[location]/order-history": [~55,[2]],
		"/(admin)/site-admin/[location]/order-history/[id]": [~56,[2]],
		"/(admin)/site-admin/[location]/staff-contact-list": [~57,[2]],
		"/(admin)/social-media/calendar": [~58,[2]],
		"/(admin)/social-media/calendar/[year]-[month]": [~59,[2]],
		"/(admin)/social-media/preview": [~60,[5]],
		"/(staff)/staff/training": [93],
		"/(customer)/terms-of-service": [85,[6]],
		"/(customer)/to/[qrcode]": [~86,[6]],
		"/(customer)/t/v2/[qrcode]": [~84,[6]],
		"/(customer)/visit/little-venn-evp": [87,[6]],
		"/(customer)/visit/venn-bicton": [88,[6]],
		"/(customer)/visit/venn-evp": [89,[6]],
		"/(customer)/work": [90,[6,10]],
		"/(customer)/work/coffee-crafter": [~91,[6,10]],
		"/(customer)/work/coffee-crafter/thank-you": [92,[6,10]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';